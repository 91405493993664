import React from 'react'
import { H1 } from '../components/Style'
import Layout from '../components/Layout'

export default () => (
  <Layout>
    <H1>Missing page</H1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)
